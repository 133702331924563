import React from "react"
import { Link, graphql } from "gatsby"

import styles from "../css/index.module.scss"
import Layout from "../components/layout"

const ResearchIndex = ({ data, location }) => {
  const posts = data.allMarkdownRemark.nodes
  return (
    <Layout title="主页">
      <div className={styles.contentList}>
        {posts.map((post, index) => {
          return (
            <Link
              className={styles.contentItem}
              to={post.fields.slug}
              key={index}
            >
              <div className={styles.contentCover}>
                <img
                  src={post.frontmatter.cover.publicURL}
                  alt={post.frontmatter.title}
                ></img>
              </div>
              <div className={styles.contentTitle}>
                {post.frontmatter.title}
              </div>
              <div className={styles.contentType}>{post.frontmatter.type}</div>
              <div className={styles.contentDate}>{post.frontmatter.date}</div>
            </Link>
          )
        })}
      </div>
    </Layout>
  )
}

export default ResearchIndex

export const pageQuery = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { type: { eq: "科研" } } }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "YYYY.MM")
          title
          cover {
            publicURL
          }
          type
        }
      }
    }
  }
`
